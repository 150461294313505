import React from "react";
import clinicallogo from "../assets/lab1.jpg";
import acutelogo from "../assets/lab2.jpg";
import British from '../assets/BVI-Logo.jpeg'

import uvi from "../assets/UVI_stx_stt.jpg";
import goodhope from "../assets/good_hope.jpg";
import { Helmet } from "react-helmet";

// UVI_stx_stt
// good_hope

export default function Header() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {process.env.REACT_APP_LAB_NAME} - Patient Registration Powered By
          Hummingbird Health{" "}
        </title>

        <meta
          name="description"
          content={
            process.env.REACT_APP_LAB_NAME +
            "- Patient Registration Powered By Hummingbird Health"
          }
        />
      </Helmet>

      <div className="headerHolder">
        <div className="lefImage">


          {
            process.env.REACT_APP_LAB_ID === "71" ?
              <img src={British} alt="humming bird logo" />
              :
              ""
          }



          {process.env.REACT_APP_LAB_NAME === "Acute Alternative" ? (
            <img src={acutelogo} alt="humming bird logo" />
          ) : null}

          {process.env.REACT_APP_LAB_NAME === "Clinical Laboratory" ? (
            <img src={clinicallogo} alt="humming bird logo" />
          ) : null}


          {
            process.env.REACT_APP_LAB_ID === "71" ?
              <h1>British Virgin Islands Health Services Authority</h1>
              :
              ""
          }


          {process.env.REACT_APP_LAB_NAME === "STT Criminal Justice Complex" ? (
            <h1>STT Criminal Justice Complex</h1>
          ) : null}

          {process.env.REACT_APP_LAB_NAME ===
            "STX John A. Bell Correctional Facility" ? (
            <h1>STX John A. Bell Correctional Facility</h1>
          ) : null}

          {process.env.REACT_APP_LAB_NAME === "Herbert Grigg" ? (
            <h1>Herbert Grigg</h1>
          ) : null}
          {process.env.REACT_APP_LAB_ID === "83" || process.env.REACT_APP_LAB_ID === "84" || process.env.REACT_APP_LAB_ID === "85" ||
            process.env.REACT_APP_LAB_ID === "86" ? (
            <h1>{process.env.REACT_APP_LAB_NAME}</h1>
          ) : null}
          {process.env.REACT_APP_LAB_NAME === "UVI-STX" ||
            process.env.REACT_APP_LAB_NAME === "UVI-STT" ||
            process.env.REACT_APP_LAB_NAME === "Good Hope Country Day School" ||
            process.env.REACT_APP_LAB_NAME === "St. John Christian Academy" ||
            process.env.REACT_APP_LAB_NAME === "Wesleyan Academy" ||
            process.env.REACT_APP_LAB_NAME === "Faith Alive Christian Academy" ||
            process.env.REACT_APP_LAB_NAME == "Calvary Christian Academy" ||
            process.env.REACT_APP_LAB_NAME == "Moravian School" ||
            process.env.REACT_APP_LAB_NAME == "Saints Peter and Paul Catholic School" ||
            process.env.REACT_APP_LAB_NAME == "New Testament Academy" ||
            process.env.REACT_APP_LAB_NAME == "St. Thomas Seventh-Day Adventist School" ||
            process.env.REACT_APP_LAB_NAME == "Free Will Baptist Christian School" ||
            process.env.REACT_APP_LAB_NAME == "Lifeline Educational Services" ||
            process.env.REACT_APP_LAB_NAME == "Church of God Holiness Academy" ||
            process.env.REACT_APP_LAB_NAME == "St. Joseph Catholic High School" ||
            process.env.REACT_APP_LAB_NAME == "St. Mary Catholic School" ||
            process.env.REACT_APP_LAB_NAME == "St. Croix Christian Academy" ||
            process.env.REACT_APP_LAB_NAME == "St. Croix Seventh-day Adventist School" ||
            process.env.REACT_APP_LAB_NAME == "St. Croix Montessori School" ||
            process.env.REACT_APP_LAB_NAME == "St. Patrick Catholic School" ||
            process.env.REACT_APP_LAB_NAME == "Reading Rainbow School" ||
            process.env.REACT_APP_LAB_NAME == "Community Health Clinic - STX" ||
            process.env.REACT_APP_LAB_NAME == "MTOC STT" ||
            process.env.REACT_APP_LAB_NAME == "MTOC STX" ? (
            <div className="headerimage">
              <div>
                {process.env.REACT_APP_LAB_NAME === "UVI-STX" ||
                  process.env.REACT_APP_LAB_NAME === "UVI-STT" ? (
                  <img src={uvi} alt="humming bird logo" />
                ) : null}

                {process.env.REACT_APP_LAB_NAME ===
                  "Good Hope Country Day School" ? (
                  <img src={goodhope} alt="humming bird logo" />
                ) : null}
              </div>
              <div>
                {process.env.REACT_APP_LAB_NAME === "UVI-STX" ? (
                  <h1>UVI - STX</h1>
                ) : null}

                {process.env.REACT_APP_LAB_NAME === "UVI-STT" ? (
                  <h1>UVI - STT</h1>
                ) : null}

              </div>
              <div>
                {process.env.REACT_APP_LAB_NAME == "Calvary Christian Academy" ||
                  process.env.REACT_APP_LAB_NAME == "Moravian School" ||
                  process.env.REACT_APP_LAB_NAME == "Saints Peter and Paul Catholic School" ||
                  process.env.REACT_APP_LAB_NAME == "New Testament Academy" ||
                  process.env.REACT_APP_LAB_NAME == "St. Thomas Seventh-Day Adventist School" ||
                  process.env.REACT_APP_LAB_NAME == "Free Will Baptist Christian School" ||
                  process.env.REACT_APP_LAB_NAME == "Lifeline Educational Services" ||
                  process.env.REACT_APP_LAB_NAME == "Church of God Holiness Academy" ||
                  process.env.REACT_APP_LAB_NAME == "St. Joseph Catholic High School" ||
                  process.env.REACT_APP_LAB_NAME == "St. Mary Catholic School" ||
                  process.env.REACT_APP_LAB_NAME == "St. Croix Christian Academy" ||
                  process.env.REACT_APP_LAB_NAME == "St. Croix Seventh-day Adventist School" ||
                  process.env.REACT_APP_LAB_NAME == "St. Croix Montessori School" ||
                  process.env.REACT_APP_LAB_NAME == "St. Patrick Catholic School" ||
                  process.env.REACT_APP_LAB_NAME == "Reading Rainbow School" ||
                  process.env.REACT_APP_LAB_NAME == "Community Health Clinic - STX" ||
                  process.env.REACT_APP_LAB_NAME == "MTOC STT" ||
                  process.env.REACT_APP_LAB_NAME == "MTOC STX" ||  
                  process.env.REACT_APP_LAB_NAME === "St. John Christian Academy" ||
                  process.env.REACT_APP_LAB_NAME === "Wesleyan Academy" ||
                  process.env.REACT_APP_LAB_NAME === "Faith Alive Christian Academy" ? (
                  <h1>
                    {process.env.REACT_APP_LAB_NAME}
                  </h1>
                ) : (
                  ""
                )}
              </div>


            </div>
          ) : null}
        </div>

        <div className="rightImage">{/* <img src={}/> */}</div>
      </div>
    </>
  );
}
