
import Header from "./Components/Header.js";
import Footer from "./Components/Footer.js";
import Form from "./Components/Form.js";
import RegisterBanner from "./Components/RegisterBanner.js";

function App() {
  return (
    <>

    <div className="mainHolder">
      <Header/>
      <RegisterBanner/>
      <Form/>
      </div>
      <Footer/>

    </>
  );
}

export default App;
